.filter-buttons {

    & .btn-outline-dark {
        border-width: 2px;
        padding: 7px 14px;
        min-width: 55px;
        border-radius: 50px;
        margin: 10px;
    }

}