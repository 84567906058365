.company-header {
    padding: 60px 0;

    & .company-header-logo {
        padding-bottom: 10px;
        margin: auto;
        text-align: center;

        & img {
            max-width: 320px;
            max-height: 200px;
        }
    }
}

.company-slogan {
    text-align: center;

    & h2 {
        font-family: 'Arial', 'Segoe UI', sans-serif;
        font-size: 2.145em;
        line-height: 1.3;
        color: #4C4C4C;
    }
}