.card-company {
    transition: transform .2s cubic-bezier(.374, .019, .035, 1.861), -webkit-transform .2s cubic-bezier(.374, .019, .035, 1.861);
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.06);
    border: none;
    overflow: hidden;
    // border-top: 4px solid #82bd4d;

    .results-tag {
        border-radius: 8px;
        border: 1px solid #dee2e6;
        background-color: #fff;
        color: #C25700;
        display: inline-block;
        height: auto;
        margin: 0 8px 0 0;
        padding: 0 7px;
        font-size: 12px;
        line-height: 20px;
        white-space: nowrap;
    }


    &:hover {
        transform: scale(1.03);
    }

    & a {
        text-decoration: none;
    }

    & .card-img-container {
        position: relative;
        height: 200px;

        & .card-img-top {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 0;
            border-top-right-radius:0;
        }

        & .card-img-overlay {
            background: rgba(0, 0, 0, 0.52);
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            & .card-title {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.2;
            }
        }
    }

    & .card-body {
        font-size: .85em;
        min-height: 150px;
        max-height: 150px;
        overflow: hidden;
        padding: 20px;

        & .card-title {
            font-size: 1.3rem;
            margin-bottom: 20px;
        }

        & .card-text {
            margin: 0
        }
    }
}