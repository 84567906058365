.card-company {
    transition: transform .2s cubic-bezier(.374,.019,.035,1.861),-webkit-transform .2s cubic-bezier(.374,.019,.035,1.861);
    cursor: pointer;
    box-shadow: rgba(0,0,0,.06) 0px 8px 16px 0px;
    border: none;
    overflow: hidden;
    
    &:hover {
        transform: scale(1.03);
    }

    & a {
        text-decoration: none;
    }

    & .card-img-container{
        position: relative;

        & .card-img-top {
            height: 200px;
            object-fit: cover;
        }

        & .card-img-overlay {
            background: rgba(0,0,0,0.52);
            color: white;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;

            & .card-title {
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.2;
            }
        }
    }

    & .card-body {
        font-size: .85em;
        min-height: 150px;
        max-height: 150px;
        overflow: hidden;
        padding: 20px;

        & .card-title {
            font-size: 1.3rem;
            margin-bottom: 20px;
        }

        & .card-text {
            margin: 0
        }
    }
}