* {
    margin: 0;
}

body {
    font-family: 'Arial', 'Segoe UI', sans-serif;
    font-size: 18px;
    color: #222;
    background-color: #fbfcff;
    line-height: 1.5;
}

.loader {
    margin: 5rem auto;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid black;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 