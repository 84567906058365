.icon-arrow {
    padding: 0 25px 0 20px;
    height: 45px;
    line-height: 45px;
    font-weight: 600;
    white-space: nowrap;
    margin-right: 10px;
    // media query
    @media (max-width: 767px) {
        padding: 0 15px 0 10px;
    }
}
.search {
    display: flex; 
    justify-content: space-between; 
    margin-bottom: 2rem;
}
.search-input-container{
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: start;
    max-width: 400px;
}
.search-input{
    padding-left: 37px;
    height: 45px;
    width: 100% !important;
}
.search-input-preffix{
    position: absolute;
    top: 50%;
    z-index: 2;
    color: rgba(0,0,0,.65);
    line-height: 0;
    transform: translateY(-50%);
    left: 12px;
    z-index: 100;
}
.mb-4-5 {
    margin-bottom: 2rem;
}